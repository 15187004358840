<script lang="ts" setup>
const props = defineProps({
  throttle: {
    type: Number,
    default: 200,
  },
  duration: {
    type: Number,
    default: 2000,
  },
});

const indicator = useLoadingIndicator({
  duration: props.duration,
  throttle: props.throttle,
});

// Hook to app lifecycle
// TODO: Use unified loading API
const nuxtApp = useNuxtApp();
nuxtApp.hook('page:start', indicator.start);
nuxtApp.hook('page:finish', indicator.finish);
onBeforeUnmount(indicator.clear);

function useLoadingIndicator(opts: { duration: number; throttle: number }) {
  const progress = ref(0);
  const isLoading = ref(false);
  const step = computed(() => 10000 / opts.duration);

  let _timer: any = null;
  let _throttle: any = null;

  function start() {
    clear();
    progress.value = 0;
    if (opts.throttle && process.client) {
      _throttle = setTimeout(() => {
        isLoading.value = true;
        _startTimer();
      }, opts.throttle);
    } else {
      isLoading.value = true;
      _startTimer();
    }
  }
  function finish() {
    progress.value = 100;
    _hide();
  }

  function clear() {
    clearInterval(_timer);
    clearTimeout(_throttle);
    _timer = null;
    _throttle = null;
  }

  function _increase(num: number) {
    progress.value = Math.min(100, progress.value + num);
  }

  function _hide() {
    clear();
    if (process.client) {
      setTimeout(() => {
        isLoading.value = false;
        setTimeout(() => {
          progress.value = 0;
        }, 400);
      }, 500);
    }
  }

  function _startTimer() {
    if (process.client) {
      _timer = setInterval(() => {
        _increase(step.value);
      }, 100);
    }
  }

  return {
    progress,
    isLoading,
    start,
    finish,
    clear,
  };
}
</script>

<template>
  <div v-if="indicator.isLoading.value" class="loading-wrapper">
    <Loader />
  </div>
</template>

<style lang="scss" scoped>
.loader {
  min-height: 156px;
}

.loading-wrapper {
  position: fixed;
  background: #ffffff;
  opacity: 0.9;
  top: 0;
  right: 0;
  left: 0;
  width: auto;
  height: 100%;
  z-index: 999999;

  :deep(.loader__overlay) {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
}
</style>
